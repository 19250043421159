import React from 'react'
import PropTypes from 'prop-types'
import { themeStyles, colors, presets } from '../utils/theme'
import Metadata from '../components/Metadata'
import CascadeLink from '../components/CascadeLink'

import logo from '../img/logo-nopad.svg'
import logoGrey from '../img/logo-gray.svg'
import equalGrey from '../img/equal-gray@2x.png'
import trustGrey from '../img/trustpilot-gray@2x.png'
import yelp from '../img/yelp.svg'
import stars from '../img/Trustpilot_ratings_5star-RGB.svg'
import trustPilot from '../img/Trustpilot_brandmark_gr-blk_RGB.png'
import { graphql } from 'gatsby'

const TARGET_URL = 'https://secure.goloansnap.com'

const styles = {
  headerOuter: {
    borderBottom: `1px solid ${colors.lightGrey}`,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    ...themeStyles.contentWidth,
    ...themeStyles.textPadding,
    [presets.Mobile]: {
      flexDirection: 'row',
      alignItems: 'space-between',
    }
  },
  mainLogo: {
    maxHeight: '20px',
    margin: 0,
    [presets.Mobile]: {
      maxHeight: '35px',
    }
  },
  spacer: {
    display: 'flex',
    flex: 1,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    ...themeStyles.contentWidth,
    ...themeStyles.textPadding,
    maxWidth: '550px',
    [presets.Mobile]: {
      maxWidth: '750px',
    }
  },
  h1: {
    lineHeight: 1.15,
    color: '#34393C',
    marginBottom: 0,
    fontSize: '2.5em',
    [presets.Tablet]: {
      fontSize: '3.5em',
    }
  },
  button: {
    borderRadius: '2em',
    backgroundColor: '#AD68EF',
    marginTop: '0.5em',
    padding: '0.25em 1em',
    fontSize: '1.5em',
    color: 'white',
    textDecoration: 'none',
    [presets.Tablet]: {
      marginTop: '1.5em',
      fontSize: '2.5em',
      padding: '0.5em 3em',
    },
    ':hover': {
      // hack to make dark hover for any color
      boxShadow: 'inset 0 0 0 99999px rgba(0,0,0,0.2)',
    },
  },
  trustImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '50px',
    flexWrap: 'wrap',
    width: '100%',
  },
  trustImage: {
    maxHeight: '40px',
    margin: 0,
    marginLeft: '20px',
  },
  footerContainer: {
    backgroundColor: colors.veryLightGrey,
  },
  footerInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...themeStyles.contentWidth,
    ...themeStyles.textPadding,
    [presets.Mobile]: {
      flexDirection: 'row',
      alignItems: 'center',
    }
  },
  footerLink: {
    fontSize: '1em',
    color: colors.mediumGrey,
    textDecoration: 'none',
  },
  verticalLine: {
    height: '1em',
    width: '1px',
    marginLeft: '10px',
    marginRight: '10px',
    display: 'none',
    backgroundColor: colors.lightGrey,
    [presets.Mobile]: {
      display: 'inline-block',
    }
  },
  footerLinksContainer: {
    display: 'flex',
    margin: '1em 0',
    flexDirection: 'column',
    alignItems: 'center',
    [presets.Mobile]: {
      margin: 0,
      flexDirection: 'row',
      alignItems: 'center',
    }
  }
}

const Header = () => (
    <div css={styles.headerOuter}>
      <div css={styles.headerContainer}>
        <div css={styles.logoHolder}>
          <img src={logo} css={styles.mainLogo} alt='LoanSnap'/>
        </div>
        <div css={styles.spacer}/>
      </div>
    </div>
)

const Footer = () => (
    <div css={styles.footerContainer}>
      <div css={styles.footerInner}>
        <img src={logoGrey} css={{ maxHeight: '40px', margin: 0}} alt='LoanSnap'/>
        <div css={styles.footerLinksContainer}>
          <CascadeLink
            css={styles.footerLink}
            to="/legal/privacy-policy"
            analyticsTargetName='Privacy Policy'
          >
            Privacy Policy
          </CascadeLink>
          <div css={styles.verticalLine}/>
          <CascadeLink
            css={styles.footerLink}
            to="/legal/terms-and-conditions"
            analyticsTargetName='Terms and Conditions'
          >
            Terms and Conditions
          </CascadeLink>
          <div css={styles.verticalLine}/>
          <CascadeLink
            css={styles.footerLink}
            href="tel:+18886805134"
            analyticsTargetName='Call us'
          >
            Call us
          </CascadeLink>
        </div>
        <div>
          <img src={equalGrey} css={{ maxHeight: '30px', margin: 0}} alt='Equal Housing'/>
          <img src={trustGrey} css={{ maxHeight: '30px', margin: 0, marginLeft: '20px'}} alt='TrustPilot'/>
        </div>
      </div>
    </div>
)

const TrustImages = () => (
  <div css={styles.trustImageContainer}>
    <img src={yelp} css={styles.trustImage} alt='Yelp'/>
    <span css={{ marginLeft: '20px'}}>Great</span>
    <img src={stars} css={{...styles.trustImage, maxHeight: '25px', marginLeft: '5px'}} alt='TrustPilot'/>
    <img src={trustPilot} css={styles.trustImage} alt='TrustPilot'/>
  </div>
)

const BasicLandingTemplate = ({
  content,
  description,
  contentComponent,
  title,
  helmet,
  location,
  buttonText,
  buttonColorHex
}) => {
  const targetUrl = TARGET_URL
  return (
    <div css={{ display: 'flex', flex: 1, flexDirection: 'column', minHeight: '100vh'}}>
      {helmet || ''}
      <Header />
      <div css={styles.contentContainer}>
        <h1 css={styles.h1}>
          {title || "Let's find out how much you can save!"}
        </h1>
        <CascadeLink
          css={{...styles.button, backgroundColor: buttonColorHex || styles.button.backgroundColor}}
          href={targetUrl}
          analyticsTargetName='CTA Button'
        >
          { buttonText || "Save money now"}
        </CascadeLink>
        <TrustImages />
      </div>
      <div css={styles.spacer} />
      <Footer />
    </div>
  )
}

BasicLandingTemplate.propTypes = {
  buttonText: PropTypes.string,
  targetUrl: PropTypes.string,
  buttonColorHex: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BasicLandingPage = ({ data, location }) => {
  const { markdownRemark: post } = data

  return (
    <BasicLandingTemplate
      description={post.frontmatter.description}
      helmet={
        <Metadata
          title={post.frontmatter.title}
        />
      }
      title={post.frontmatter.title}
      buttonText={post.frontmatter.buttonText}
      targetUrl={post.frontmatter.targetUrl}
      buttonColorHex={post.frontmatter.buttonColorHex}
      location={location}
    />
  )
}

BasicLandingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BasicLandingPage

export const pageQuery = graphql`
  query BasicLandingPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        buttonText
        targetUrl
        buttonColorHex
      }
    }
  }
`
